<template>
    <div class="content">
        <el-table :data="orderList" style="width: 100%" v-loading="isloading" stripe :row-style="isRed"
            @selection-change="selected">
            <el-table-column type="selection" width="55" />
            <el-table-column prop="merchant_no" label="商户号" min-width="120" />
            <el-table-column prop="merchant_name" label="商户名称" min-width="180" show-overflow-tooltip />
            <el-table-column prop="store_name" label="门店名称" min-width="180" show-overflow-tooltip />
            <el-table-column prop="created_at" label="创建时间" min-width="200">
                <template #default="scope">
                    <span>{{ dayjs(scope.row.created_at).format("YYYY-MM-DD H:mm:ss") }} </span>
                </template>
            </el-table-column>
            <el-table-column prop="device_id" label="设备ID(终端)" min-width="120" :align="'center'">
                <template #default="scope">
                    <span>{{ scope.row.device_id }} </span>
                </template>
            </el-table-column>
            <el-table-column prop="status_format" label="退款状态" min-width="120" :align="'center'">
                <template #default="scope">
                    <span v-if="scope.row.status_format == 'Success'">
                        <el-tag type="success">退款成功</el-tag>
                    </span>
                    <span v-else>
                        <el-tag type="danger">退款失败</el-tag>
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="trade_no" label="平台订单号" min-width="200">
                <template #default="scope">
                    <div class="icon-box">
                        <el-tooltip effect="dark" :content="scope.row.trade_no" placement="top-start">
                            <span class="text-overflow">{{ scope.row.trade_no }}</span>
                        </el-tooltip>
                        <span class="copy icon">
                            <el-icon @click="handleCopy(scope.row.trade_no)">
                                <CopyDocument />
                            </el-icon>
                        </span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="refund_no" label="平台退款单号" min-width="180">
                <template #default="scope">
                    <div class="icon-box">
                        <el-tooltip effect="dark" :content="scope.row.refund_no" placement="top-start">
                            <span class="text-overflow">{{ scope.row.refund_no }}</span>
                        </el-tooltip>
                        <span class="copy icon">
                            <el-icon @click="handleCopy(scope.row.refund_no)">
                                <CopyDocument />
                            </el-icon>
                        </span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="out_refund_no" label="商户退款单号" min-width="200">
                <template #default="scope">
                    <div class="icon-box">
                        <el-tooltip effect="dark" :content="scope.row.out_refund_no" placement="top-start">
                            <span class="text-overflow">{{ scope.row.out_refund_no }}</span>
                        </el-tooltip>
                        <span class="copy icon">
                            <el-icon @click="handleCopy(scope.row.out_refund_no)">
                                <CopyDocument />
                            </el-icon>
                        </span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="agent_name" label="代理商名称" min-width="180" show-overflow-tooltip />
            <el-table-column prop="refund_amount" label="退款金额" min-width="100">
                <template #default="scope">
                    <span>{{ scope.row.refund_amount }} 元</span>
                </template>
            </el-table-column>
            <el-table-column prop="payment_channel_id" label="支付渠道" min-width="100" :align="'center'">
                <template #default="scope">
                    <span>{{ getChannelLabel(scope.row.payment_channel_id) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="payment_channel_code" label="支付渠道编码" min-width="120" />
            <el-table-column prop="payment_method" label="支付方式" min-width="100" />
            <el-table-column label="操作" :align="'center'" fixed="right">
                <template #default="scope">
                    <el-link :underline="false" @click="handleMore(scope.row)" type="primary">详情</el-link>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="空数据" :image-size="200" />
            </template>
        </el-table>
    </div>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits, computed } from 'vue';
import { CopyDocument } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import dayjs from "dayjs"
// 接收父组件传递的 orderList
const props = defineProps(['orderList', 'isloading', 'channelList']);
const emit = defineEmits(['show-detail']);

const orderList = ref()

// 监听 orderList 的变化
watch(
    () => props.orderList,
    (newVal) => {
        orderList.value = newVal; // 直接使用父组件传递的数据
    },
    { immediate: true }
);

const selectedArrData = ref([]);

// 复选框被选中的事件
const selected = (row) => {
    selectedArrData.value = row;
};

// 设置选中行的样式
const isRed = ({ row }) => {
    const checkIdList = selectedArrData.value.map((item) => item.id);
    if (checkIdList.includes(row.id)) {
        return {
            backgroundColor: "#cde8f8"
        };
    }
};

// 使用 map 方法将 channelList 转换为一个映射对象
const channelMap = computed(() => {
    const map = {};
    props.channelList.map(channel => {
        map[channel.value] = channel.label;
    });
    return map;
});

// 根据 payment_channel_id 获取对应的 label
const getChannelLabel = (paymentChannelId) => {
    return channelMap.value[paymentChannelId] || '未知渠道'; // 如果找不到对应的 label，返回默认值
};


const handleCopy = (data) => {
    ElMessage({
        message: '复制成功',
        type: 'success',
    })
    let tempcopy = document.createElement("input")
    tempcopy.value = data
    document.body.appendChild(tempcopy)
    tempcopy.select()
    document.execCommand('Copy')
    document.body.removeChild(tempcopy) // 复制完成后，移除临时输入框
}

// 处理详情按钮点击事件
const handleMore = (orderData) => {
    emit('show-detail', orderData); // 触发 show-detail 事件，并传递当前行的订单数据
};
</script>

<style lang="less" scoped>
.content {
    .icon-box {
        display: flex;
        flex-direction: row;

        .text-overflow {
            width: 90%;
            overflow: hidden;
            white-space: nowrap !important;
            text-overflow: ellipsis !important;
        }

        .copy {
            color: #409EFF !important;
            cursor: pointer;
        }

        .copyed {
            color: #52c41a !important;
        }

        .icon {
            font-size: 14px !important;

        }
    }
}


/* 当鼠标悬停在表格的行上时改变背景色 */
.el-table {
    --el-table-row-hover-bg-color: #cde8f8;
}

/* src/assets/styles/global.css */
/* 滚动条整体部分 */
::v-deep .el-scrollbar__bar {
    opacity: 1;
    /* 使滚动条不透明 */
}

/* 横向滚动条的滑块 */
::v-deep .el-scrollbar__bar.is-horizontal .el-scrollbar__thumb {
    border-radius: 1px;
    /* 滑块的圆角 */
    background-color: #818181;
    /* 滑块的背景颜色 */
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    /* 滑块的阴影 */
}

/* 纵向滚动条的滑块 */
::v-deep .el-scrollbar__bar.is-vertical .el-scrollbar__thumb {
    border-radius: 1px;
    background-color: #818181;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
}

/* 滚动条的轨道部分 */
::v-deep .el-scrollbar__wrap {
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        /* 轨道的背景颜色 */
    }
}

/* 修改滑块宽度 */
::v-deep .el-scrollbar__bar.is-horizontal {
    height: 12px;
}

::v-deep .el-scrollbar__bar.is-vertical {
    width: 12px;
}
</style>