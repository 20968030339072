import { GetTodayTime, GetTodayEndTime } from "@/tools/time";


const searchConfig = (searchOptions) => {
  return {
    formItems: [
      {
        type: "input",
        prop: "trade_no",
        label: "平台订单号",
        placeholder: "请输入平台订单号",
      },
      {
        type: "input",
        prop: "refund_no",
        label: "平台退款单号",
        placeholder: "请输入平台退款单号",
      },
      {
        type: "input",
        prop: "out_refund_no",
        label: "商户退款单号",
        placeholder: "请输入商户退款单号",
      },
      {
        type: "input",
        prop: "device_id",
        label: "终端ID",
        placeholder: "请输入终端ID",
      },
      {
        type: "input",
        prop: "merchant_name",
        label: "商户名称",
        placeholder: "请输入商户名称",
      },
      {
        type: "input",
        prop: "agent_name",
        label: "代理商名称",
        placeholder: "请输入代理商名称",
      },
      {
        type: "input",
        prop: "store_name",
        label: "门店名称",
        placeholder: "请输入门店名称",
      },
      {
        type: "input",
        prop: "merchant_no",
        label: "商户号",
        placeholder: "请输入商户号",
      },
      {
        type: "select",
        label: "支付方式",
        prop: "payment_method_id",
        options: searchOptions.payTypeList,
      },
      {
        type: "select",
        label: "支付渠道",
        prop: "payment_channel_id",
        options: searchOptions.channelList,
      },
      {
        type: "date-picker",
        prop: "time_start",
        label: "创建时间",
        placeholder: "请选择创建时间",
        initialValue: GetTodayTime(),
      },
      {
        type: "date-picker",
        prop: "time_end",
        label: "截至时间",
        placeholder: "请选择截至时间",
        initialValue: GetTodayEndTime(),
      }
    ],
  };
};

export default searchConfig;
